<template>
  <div class="common-layout">
    <el-container>
      
      <el-aside width="200px" v-if="$route.name != 'home'"><el-col :span="24">
      <h5 class="mb-2">Hire & Seek</h5>
      <el-menu
        
        class="el-menu-vertical-demo"
        
      >
       
        <el-menu-item @click="$router.push({name:'proposal'})">Proposal</el-menu-item>
        <el-menu-item  @click="$router.push({name:'employer'})">Employer</el-menu-item>
        <el-menu-item  @click="$router.push({name:'job_seeker'})">Job Seeker</el-menu-item>

        
      </el-menu>
      </el-col></el-aside>
      <el-main>
         <router-view/>
      </el-main>
    </el-container>
  </div>
 
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
