
<template>
  <div class="common-layout">
    <el-container >
      <el-main style="margin-top: 15%;">
        <el-row :gutter="10" >
      
          <el-form label-width="120px" style="margin:auto;">
            <h2>Newstars</h2> 
            <el-form-item label="User">
              <el-input v-model="User" placeholder="Please input" style="width: 400px" />
            </el-form-item>
            <el-form-item label="Password">
              <el-input
                v-model="Password"
                type="password"
                placeholder="Please input password"
                show-password
                style="width: 400px"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm()">login</el-button>
            </el-form-item>
          </el-form>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "proposalView",

  data() {
    return {
      User: "",
      Password: "",
    };
  },

  mounted() {},

  methods: {
    submitForm() {
      if(this.User == 'admin' && this.Password == 'Newstars123'){
        this.$router.push({name:'proposal'})
        this.$message.success('Login Successful!')
      }else{
        this.$message.error('Password error!')
      }
    },
  },
};
</script>



      